import { ref } from "@vue/reactivity";
import engine from "@/core/DataAPI"

// import { watch } from "vue";
// import { computed } from "@vue/runtime-core";
import { onMounted } from "vue";
// const Buffer = require('buffer').Buffer

// import moment from "moment";

export default function homeHook() {

    // const materialSelectionTitle = ref('Notre sélection de vélos de ville à louer en ligne ou en magasin (engagement minimum 3 mois)')
    const headerTitle = ref("")
    const headerSecondeTitle = ref("")
    const familyIsLoading = ref(true)
        // const materialList = ref(undefined)
    const familyList = ref(undefined)

    const getFamilyListe = function() {
        console.log("getFamilyListe");
        engine.Family_List(function(data) {
            console.log("getFamilyListe IN", data);
            if (data.code == 0) {
                familyList.value = Object.values(data.data.data).map((val) => val[0])
                    // for (let i = 0; i < familyList.value.length; i++) {
                    //     let fileName = familyList.value[i].Nom_Famille_Principale + '.jpg'
                    // engine.get_material_pic_from_name(fileName, function(data2) {
                    //     if (data2.code == 0) {
                    //         let fileData = data2.data.result.fileBinary
                    //         familyList.value[i].url = "data:image/png;base64," + new Buffer.from(fileData, 'binary').toString('base64');
                    //         if (familyList.value.filter((val) => val.url == undefined).length == 1) {
                    //             familyIsLoading.value = false
                    //         }
                    //     }
                    // })
                    // }
                familyIsLoading.value = false
            }
        })
    }

    const scrollToId = function(id) {
        setTimeout(function() {
            document.getElementById(id).scrollIntoView({ block: 'start', behavior: 'smooth' })
        }, 100);
    }

    onMounted(() => {
        getFamilyListe()
    })

    return {
        headerTitle,
        headerSecondeTitle,
        // materialSelectionTitle,
        // materialList,
        scrollToId,
        familyIsLoading,
        familyList
    };

}