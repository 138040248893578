<template>
  <client-header :login="login" :verifClientIsOver="verifClientIsOver" :headerTitle="headerTitle"
    :headerSecondeTitle="headerSecondeTitle" :userInformation="userInformation" :isValidUser="isValidUser"
    :isUserConnected="isUserConnected">
  </client-header>
  <client-information v-if="verifClientIsOver == true && isValidUser" :updateUserInfo="updateUserInfo" class="main-vue"></client-information>
  <v-col cols="12" v-else align="center">
    <basic-loading style="margin-top:30vh"></basic-loading>
  </v-col>
</template>
  
<script>

import ClientHeader from '@/components/header/header-general.vue'
import ClientInformation from '@/components/client/compte_information.vue'
import clientHook from "@/hooks/clientHook"

import BasicLoading from '@/components/basic-loading.vue'

export default {
  setup() {
    const { login, userInformation, isValidUser, verifClientIsOver, isUserConnected, updateUserInfo } = clientHook()
    return { login, userInformation, isValidUser, verifClientIsOver, isUserConnected, updateUserInfo }
  },
  data() {
    return {
      headerTitle: "Espace client",
      headerSecondeTitle: "Informations nécessaires utilisées pour la réservation en ligne"
    }
  },
  watch: {
    isValidUser(val) {
      if (val == false) {
        this.$router.push('/')
      }
    }
  },
  components: {
    ClientHeader,
    ClientInformation,
    BasicLoading
  }
}
</script>