<template>
    <v-col>
        <v-col v-if="getMaterialListe && selectedFamilyToShowLocal" style="background-color: var(--C6)">
            <v-col align="left" class="mb-3 px-0">
                <v-row justify="center" align="center">
                    <v-col cols="auto">
                        <h3 style="color: var(--C3);font-weight: bold;">
                            {{ materialSelectionTitle }}
                        </h3>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto">
                            <v-select v-model="selectedFamilyToShowLocal" :items="familyList" item-title="WEB_NOM_FAMILLE"
                            style="text-transform: uppercase;background-color: white;border-radius: 5px" label="Catégorie séléctionner" variant="solo"
                            class=""  hide-details return-object>
                        </v-select>
                    </v-col>
                </v-row>
            </v-col>
            <v-row>
                <v-col v-for="(mat, i) in getMaterialListe" :key="i" cols="12" md="6" sm="12" lg="4">
                    <v-card class="pa-2 cardHome" variant="flat" width="" @click="materialIsAvailable(mat)" height="100%"
                        :style="{
                            'border-color': selectedMaterial && selectedMaterial.id == mat.id ? 'var(--C1) !important' : '',
                            'background-color': mat.qty <= 0 ? '#FFEBEE' : 'white'
                        }">
                        <div align="center">
                            <h2>
                                {{ mat.titre1 }}
                            </h2>
                            <v-row align="center" class="">
                                <v-col cols="6" sm="6">
                                    <img :src="'./sub_family/' + mat.nom + '.jpg'" style="width: 100%;height: 100%;"
                                        :alt="mat.titre1"><img>
                                </v-col>
                                <v-col cols="6">
                                    <div align="right">
                                        <h5 v-if="mat.titre2 && mat.titre2.includes(';')" class="pa-2">
                                            <div v-for="elem in mat.titre2.split(';')" :key="elem">
                                                {{ elem }}
                                            </div>
                                        </h5>
                                        <h5 v-else>
                                            {{ mat.titre2 }}
                                        </h5>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-col align="center">
                                <h5>
                                    {{ mat.titre3 }}
                                </h5>
                            </v-col>
                            <div v-if="mat.qty <= 0" align="center">
                                <h3 style="color:rgb(239, 83, 80)">
                                    Actuellement indisponible
                                </h3>
                                <h5 style="color:rgb(239, 83, 80)">
                                    ( continuer pour être informé de son retour en
                                    disponibilité )
                                </h5>
                            </div>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
        <v-col v-else>
            <v-progress-circular :size="150" :width="3" color="purple" indeterminate></v-progress-circular>
        </v-col>
    </v-col>
    <v-snackbar v-model="snack.show" timeout="3000" :color="snack.color">
        <h3>
            {{ snack.value }}
        </h3>
    </v-snackbar>
</template>
  
<script>
export default {
    data() {
        return {
            snack: {
                show:false,
                color:undefined,
                value:undefined,
            },
            materialSelectionTitle: 'Sélectionner un type de materiel',
            selectedFamilyToShowLocal: undefined
        }
    },
    mounted() {
        this.setFamilyFromQuery()
    },
    watch: {
        selectedFamilyToShowLocal() {
            this.setMaterial(undefined)
        }
    },
    methods: {
        materialIsAvailable(mat) {
            // let familyTab = {
            //     "TROTTINETTE": "web_nb_reservation_trottinette",
            //     "VAE": "web_nb_reservation_vae",
            //     "VELO": "web_nb_reservation_velo",
            //     "VOITURE SANS PERMIS": "web_nb_reservation_voiture_sans_permis",
            // }

            // if (this.userInformation == undefined || this.userInformation[familyTab[mat.famille]] == 0) {
                this.setMaterial(mat)
            // } else {
            //     this.snack.color = 'red'
            //     this.snack.value = 'Vous disposez déjà d\'une location en cours dans cette catégorie'
            //     this.snack.show = true
            // }
        },
        setFamilyFromQuery() {
            if (this.$route.query['family']) {
                this.selectedFamilyToShowLocal = this.familyList.find((val) => val.Nom_Famille_Principale == this.$route.query['family'])
            }
            if (this.selectedFamilyToShowLocal == undefined) {
                this.selectedFamilyToShowLocal = this.familyList[0]
            }
            this.$router.replace({ 'query': null });
        }
    },
    computed: {
        getMaterialListe() {
            if (this.selectedFamilyToShowLocal == undefined)
                return this.materialList.filter((val) => val.index > 0)
            else {
                return this.materialList.filter((val) => this.selectedFamilyToShowLocal.Nom_Famille_Principale == val.famille).filter((val) => val.index > 0)
            }
        }
    },
    props: {
        userInformation: {},
        materialList: {},
        setMaterial: {
            type: Function,
        },
        familyList: {},
        selectedMaterial: {}
    }
}
</script>
  
<style></style>