<template>
  <div class="stripe" align=center>
    <v-col cols="6">
      <v-card class="pa-4" style="border: solid 1px lightgray;background-color: #F5F5F5;" variant="text">
        <div id="payment-form">
          <div id="link-authentication-element">
            <!--Stripe.js injects the Link Authentication Element-->
          </div>
          <div id="payment-element">
            <!--Stripe.js injects the Payment Element-->
          </div>
          <v-row>
            <v-col cols="6">
              <button id="submit" @click="handleSubmit">
                <div class="spinner hidden" id="spinner"></div>
                <span id="button-text">Payer {{ stripePrice / 100 }} EUR </span>
              </button>
            </v-col>
            <v-col cols="6">
              <button class="" @click="close()" style="background-color: #E57373;">Annuler</button>
            </v-col>
          </v-row>
          <div id="payment-message" class="hidden"></div>
        </div>
      </v-card>
    </v-col>
  </div>
</template>

<script >

import { loadStripe } from '@stripe/stripe-js';

// import engine from "@/core/DataAPI"

export default {
  data() {
    return {
      pk: 'pk_test_ahgPfDAEug0DVswMYTTPShWU00RWXNOa9y',
      emailAddress: undefined,
      stripe: undefined,
      elements: undefined
    };
  },
  async mounted() {
    this.stripe = await loadStripe(this.pk);
    this.initialize()
    this.checkStatus();
    // document
    //   .querySelector("#payment-form")
    //   .addEventListener("submit", this.handleSubmit);
    // console.log(3)
  },
  props: {
    clientSecret: {},
    stripePrice: {},
    validationFunction: { type: Function },
    urlRedirect: {}
    // close: { type: Function }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async initialize() {
      console.log("initialize", this.clientSecret);
      let that = this
      const appearance = {
        theme: 'stripe',
      };
      that.elements = that.stripe.elements({ appearance, clientSecret: that.clientSecret });
      const linkAuthenticationElement = that.elements.create("linkAuthentication");
      linkAuthenticationElement.mount("#link-authentication-element");
      const paymentElementOptions = {
        layout: "tabs",
      };
      const paymentElement = that.elements.create("payment", paymentElementOptions);
      paymentElement.mount("#payment-element");
    },

    async checkStatus() {
      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );

      if (!clientSecret) {
        return;
      }

      const { paymentIntent } = await this.stripe.retrievePaymentIntent(clientSecret);
      console.log("checkStatus", paymentIntent);
      switch (paymentIntent.status) {
        case "succeeded":
          this.showMessage("Payment succeeded!");
          break;
        case "processing":
          this.showMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          this.showMessage("Your payment was not successful, please try again.");
          break;
        default:
          this.showMessage("Something went wrong.");
          break;
      }
    },
    async handleSubmit() {
      console.log("handleSubmit");
      let that = this
      // e.preventDefault();

      this.elements.submit()
        .then(async function (err) {
          console.log(0, err);
          if (err.error == undefined) {
            console.log(1);
            that.setLoading(true)
            if (that.validationFunction) {
              console.log("renew");
              that.validationFunction(async function () {
                const { error } = await that.stripe.confirmPayment({
                  elements: that.elements,
                  confirmParams: {
                    return_url: "https://demo-location.accen.me/api/v1/" + that.urlRedirect,
                    // return_url: "https://demo-location.accen.me/api/v1/reservation/payment" + that.urlRedirect,
                    payment_method_data: {
                      billing_details: {
                        name: 'Christian',
                        email: 'christian.betta@accen.fr',
                      }
                    },
                  },
                });
                if (error.type === "card_error" || error.type === "validation_error") {
                  that.showMessage(error.message);
                } else {
                  that.showMessage("An unexpected error occurred.");
                }
                that.setLoading(false)
              })
            } else {
              console.log("normal");
              const { error } = await that.stripe.confirmPayment({
                elements: that.elements,
                confirmParams: {
                  return_url: "https://demo-location.accen.me/api/v1/reservation/payment",
                  payment_method_data: {
                    billing_details: {
                      name: 'Christian',
                      email: 'christian.betta@accen.fr',
                    }
                  },
                },
              });
              if (error.type === "card_error" || error.type === "validation_error") {
                that.showMessage(error.message);
              } else {
                that.showMessage("An unexpected error occurred.");
              }
              that.setLoading(false)
            }
          }
        });

    },
    showMessage(messageText) {
      const messageContainer = document.querySelector("#payment-message");

      messageContainer.classList.remove("hidden");
      messageContainer.textContent = messageText;

      setTimeout(function () {
        messageContainer.classList.add("hidden");
        messageText.textContent = "";
      }, 4000);
    },

    // Show a spinner on payment submission
    setLoading(isLoading) {
      if (isLoading) {
        // Disable the button and show a spinner
        document.querySelector("#submit").disabled = true;
        document.querySelector("#spinner").classList.remove("hidden");
        document.querySelector("#button-text").classList.add("hidden");
      } else {
        document.querySelector("#submit").disabled = false;
        document.querySelector("#spinner").classList.add("hidden");
        document.querySelector("#button-text").classList.remove("hidden");
      }
    },
  },
};
</script>

<style>
/* Variables */
* {
  box-sizing: border-box;
}

.stripe form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

.stripe .hidden {
  display: none;
}

.stripe #payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

.stripe #payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
.stripe button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

.stripe button:hover {
  filter: contrast(115%);
}

.stripe button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.stripe .spinner,
.stripe .spinner:before,
.stripe .spinner:after {
  border-radius: 50%;
}

.stripe .spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.stripe .spinner:before,
.stripe .spinner:after {
  position: absolute;
  content: "";
}

.stripe .spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.stripe .spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}
</style>