import { ref } from "@vue/reactivity";
import engine from "@/core/DataAPI"

// import { watch } from "vue";
// import { computed } from "@vue/runtime-core";
import { onMounted } from "vue";
// import JSZip from 'jszip';

// const JSZip = require('jszip');


// import moment from "moment";
// const Buffer = require('buffer').Buffer
// const zip = new JSZip();

export default function reservationHook() {


    const headerTitle = ref("Réservation")
    const headerSecondeTitle = ref("")

    const materialList = ref(undefined)
    const verifAllFiel = ref(false)
    const agencyListe = ref(undefined)
    const selectedMaterial = ref(undefined)
    const selectedAccessoire = ref([])
    const selectedAgency = ref(undefined)
    const selectedMeetId = ref(undefined)
    const selectedMeetDate = ref(undefined)
    const selectedPaymentType = ref(undefined)
    const filesToLoadListe = ref(undefined)
    const selectedPaymentAmount = ref(undefined)
    const familyList = ref(undefined)
    const step = ref(1)
    const materialIsLoading = ref(true)
    const materialsImages = ref({})

    const filesInfo = ref({ 'filename': undefined, 'key': undefined, 'label': undefined, 'type': 'file', icon: 'mdi-file-upload', 'fileExiste': false, value: undefined, mandatory: 'Oui' })
    const filesToLoad = ref([
        { 'key': 'IBAN', 'label': 'Iban', 'type': 'text', icon: 'mdi-menu-right', isValide: false, errorMessage: 'Iban non valide', value: undefined },
    ])


    // const getMaterialsListe = function() {
    //     engine.Material_List(function(data) {
    //         if (data.code == 0) {
    //             materialList.value = data.data
    //         }
    //     })
    // }
    const getMaterialsListe = function() {
        engine.Material_List(function(data) {
            if (data.code == 0) {
                for (let i = 0; i < data.data.length; i++) {
                    let mat = data.data[i]
                    let accPhoto = {}
                    mat.url = materialsImages.value[mat.nom.replaceAll('/', '@')]
                    for (let a = 0; a < mat.accessories.length; a++) {
                        let fileName = mat.accessories[a]['ComplementLocation::imageLabel']
                        if (accPhoto[fileName] == undefined) {
                            accPhoto[fileName] = materialsImages.value[fileName.replaceAll('/', '@')]
                            mat.accessories[a]['url'] = accPhoto[fileName]
                        } else {
                            mat.accessories[a]['url'] = accPhoto[fileName]
                        }
                    }
                }
                materialList.value = data.data
                materialIsLoading.value = false
            }
        })
    }

    const getAgencyListe = function() {
        engine.get_agency_liste(function(data) {
            if (data.code == 0) {
                agencyListe.value = data.data.data.data.map((val) => val.fieldData).filter((val) => val.PR_ID_AG != 0).sort((a, b) => a.INDEX - b.INDEX)
            }
        })
    }

    const setMaterial = function(material) {
        selectedAccessoire.value = []
        selectedAgency.value = undefined
        selectedPaymentType.value = undefined
        selectedPaymentAmount.value = undefined
        selectedMeetId.value = undefined
        selectedMeetDate.value = undefined
        selectedPaymentType.value = undefined
        selectedMaterial.value = material
        if (material) {
            selectedMaterial.value = material
            filesToLoadListe.value = material.pjListe
            if (material && material.qty > 0) {
                step.value = 2
                scrollToId('agency')
            } else {
                step.value = 1
                scrollToId('contact')
            }
        } else {
            step.value = 1
        }
    }

    const setMeetId = function(newId) {
        selectedMeetId.value = newId['Clé primaire']
        selectedMeetDate.value = newId['data']['dates'].start
        step.value = 4
        scrollToId('payment')
    }

    const setSelectedPaymentType = function(newPaymentTypeIdx) {
        selectedPaymentAmount.value = undefined
        if (newPaymentTypeIdx == undefined) {
            step.value = 4
        } else {
            selectedPaymentType.value = selectedMaterial.value.subscriptions[newPaymentTypeIdx]
            scrollToId('subscription')
            step.value = 5
        }
    }

    const setSelectedPaymentAmount = function(newPaymentIdx) {
        step.value = 5
        if (newPaymentIdx == undefined) {
            selectedPaymentAmount.value = undefined
        } else {
            selectedPaymentAmount.value = selectedPaymentType.value.prices[newPaymentIdx]
            step.value = 6
            scrollToId('files')
        }
    }

    const getFamilyListe = function() {
        engine.Family_List(function(data) {
            if (data.code == 0) {
                familyList.value = Object.values(data.data.data).map((val) => val[0])
            }
        })
    }


    const updateSelectedAccessoireByIndex = function(newAccessoire) {
        if (newAccessoire) {
            selectedAccessoire.value = newAccessoire.map((val) => selectedMaterial.value.accessories[val])
            for (let i = 0; i < selectedAccessoire.value.length; i++) {
                let price = materialList.value.find((val) => val.designation == selectedAccessoire.value[i]['ComplementLocation::Désignation'])
                if (price) {
                    selectedAccessoire.value[i]['price'] = price.subscriptions
                    selectedAccessoire.value[i]['id_accen'] = price.id_accen
                }
            }
        } else
            selectedAccessoire.value = []
    }

    const setAgency = function(agency) {
        // selectedAgency.value = agencyListe.value[agencyIdx]
        selectedAgency.value = agency
        step.value = 3
    }


    const resetFilesToLoad = function() {
        filesToLoad.value = [
            { 'key': 'IBAN', 'label': 'Iban', 'type': 'text', icon: 'mdi-menu-right', isValide: false, errorMessage: 'Iban non valide', value: undefined }
        ]
    }

    const addFilesToLoad = function(elem) {
        filesToLoad.value.push(elem)
    }
    const validAllMandatoryFiles = function(isValide) {
        if (isValide) {
            step.value = 7
            scrollToId('recap')
        }
        // else {
        //     step.value = 6
        //     scrollToId('files')
        // }
    }

    // const getAllMaterialsPics = function() {
    //     engine.get_all_materials_pics(function(data) {
    //         zip.loadAsync(data.data.fileBinary.data)
    //             .then(function() {
    //                 zip.forEach(function(relativePath, zipEntry) {
    //                     if (!zipEntry.dir) {
    //                         zipEntry.async("uint8array")
    //                             .then(function(fileData) {
    //                                 let url = "data:image/png;base64," + new Buffer.from(fileData, 'binary').toString('base64');
    //                                 materialsImages.value[zipEntry.name.split('/')[1].split('.')[0]] = url
    //                             });
    //                     }
    //                 });
    //             })
    //     })
    // }

    const scrollToId = function(id) {
        setTimeout(function() {
            document.getElementById(id).scrollIntoView({ block: 'start', behavior: 'smooth' })
        }, 100);
    }

    onMounted(() => {
        getMaterialsListe()
        getFamilyListe()
            // getAllMaterialsPics()
        getAgencyListe()
    })

    return {
        headerTitle,
        materialList,
        setMaterial,
        selectedMaterial,
        updateSelectedAccessoireByIndex,
        agencyListe,
        setAgency,
        selectedAgency,
        step,
        setMeetId,
        selectedPaymentType,
        setSelectedPaymentType,
        setSelectedPaymentAmount,
        validAllMandatoryFiles,
        selectedPaymentAmount,
        selectedAccessoire,
        headerSecondeTitle,
        scrollToId,
        selectedMeetId,
        materialIsLoading,
        verifAllFiel,
        selectedMeetDate,
        filesToLoadListe,
        familyList,
        filesInfo,
        filesToLoad,
        addFilesToLoad,
        resetFilesToLoad
    };

}