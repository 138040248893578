<template>
  <div align=center v-if="show">
    <v-col md="7" cols="12">
      <v-card>
        <v-col style="background-color: var(--C1);">
          <h2 style="color:white;">Créer un compte</h2>
        </v-col>
        <v-col>
          <v-row style="max-height: 70vh;overflow: auto;border: solid 1px lightgrey;" align="center">
            <v-col v-for="(elem, i) in accountCreationForm" :key="i" :cols="elem.size">
              <v-text-field v-model="elem.value" :type="elem.type" :label="elem.label" hide-details
                :style="elem.showErrorMsg ? 'background-color: #FFEBEE;' : 'background-color:var(--C5)'"
                variant="outlined"></v-text-field>
              <v-card v-if="elem.showErrorMsg"
                style="font-size:15px;width:100%;background-color: #E57373;border-radius: 7px;color:white" class="mt-1"
                variant="tonal">
                <div align="center" style="width:100%">
                  {{ elem.errorLabel }}
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col>
              <v-btn style="color:white;background-color: var(--C1);" @click="verifForm()">Valider ma création</v-btn>
            </v-col>
            <v-col>
              <v-btn  color="#E57373" style="width:100%;color:white" @click="closeModal()">Fermer</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-col>
  </div>
  <v-snackbar v-model="snackbar.isOpen" :color="snackbar.color" :timeout="snackbar.timeout">
    {{ snackbar.text }}
  </v-snackbar>
</template>

<script>

import engine from "@/core/DataAPI"
import moment from 'moment'

export default {
  data() {
    return {
      show:true,
      accountCreationForm: [
        { key: 'lastname', label: 'Nom  *', showErrorMsg: false, errorLabel: 'Le nom est nécessaire', type: 'text', size: 6, value: undefined },
        { key: 'firstname', label: 'Prénom *', showErrorMsg: false, errorLabel: 'Le Prénom est nécessaire', type: 'text', size: 6, value: undefined },
        { key: 'birthdate', label: 'Date de naissance *', showErrorMsg: false, errorLabel: 'Vous devez avoir 18 ans ou + pour pouvoir créer un compte', type: 'date', size: 12, value: undefined },
        { key: 'address', label: 'Adresse *', showErrorMsg: false, errorLabel: 'L\'Adresse est nécessaire', type: 'text', size: 12, value: undefined },
        { key: 'zipcode', label: 'Code postal *', showErrorMsg: false, errorLabel: 'Le code postal est nécessaire', type: 'number', size: 6, value: undefined },
        { key: 'city', label: 'Ville *', showErrorMsg: false, errorLabel: 'La ville est nécessaire', type: 'text', size: 6, value: undefined },
        { key: 'mobile', label: 'Numéro de téléphone *', showErrorMsg: false, errorLabel: 'Le numéro de téléphone est nécessaire', type: 'number', size: 12, value: undefined },
        { key: 'email', label: 'Email *', showErrorMsg: false, errorLabel: 'L\'Email est nécessaire', type: 'email', size: 12, value: undefined },
        { key: 'pwd', label: 'Le mot de passe *', showErrorMsg: false, errorLabel: 'Le mot de passe doit contenir minumim 8 caractères, 1 majuscule, 1 minuscule, 1 nombre, 1 caractère spécial [@$!%*?&]', type: 'password', size: 12, value: undefined },
        { key: 'pwdconfirm', label: 'Répétition du mot de passe *', showErrorMsg: false, errorLabel: 'Les deux mot de passe doivent être identique', type: 'password', size: 12, value: undefined },
      ],
      snackbar: {
        isOpen: false,
        timeout: 3000,
        text: undefined
      }
    }
  },
  props: {
    closeModal: Function
  },
  methods: {
    verifForm() {
      let formIsValid = true
      for (let i = 0; i < this.accountCreationForm.length; i++) {
        let elem = this.accountCreationForm[i]
        elem.showErrorMsg = false
        if (elem.value == undefined || elem.value.length == 0) {
          elem.showErrorMsg = true
        }
        else {
          if (elem.key == 'email')
            this.verifEmail(elem)
          else if (elem.key == 'birthdate') {
            let age = parseInt(moment().format('YYYY')) - parseInt(moment(elem.value).format("YYYY"))
            if (age >= 18) {
              elem.showErrorMsg = false
            } else
              elem.showErrorMsg = true
          }
          else if (elem.key == 'pwd')
            this.verifPwd(elem)
          else if (elem.key == 'pwdconfirm') {
            if (elem.value == this.accountCreationForm.find((val) => val.key == 'pwd').value)
              elem.showErrorMsg = false
            else
              elem.showErrorMsg = true
          }
        }

        if (elem.showErrorMsg == true) {
          formIsValid = false
        }
      }

      if (formIsValid)
        this.creatAccount()
    },
    verifEmail(elem) {
      var regExpMail = /^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+.[a-zA-Z]{2,})$/gm;
      if (!regExpMail.test(elem.value)) elem.showErrorMsg = true
      else elem.showErrorMsg = false
    },
    verifPwd(elem) {
      var regExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&+=-_]{8,}$/gm;
      if (!regExp.test(elem.value)) elem.showErrorMsg = true
      else elem.showErrorMsg = false
    },
    creatAccount() {
      let that = this
      let account = {}

      for (let i = 0; i < this.accountCreationForm.length; i++) {
        let elem = this.accountCreationForm[i]
        account[elem.key] = elem.value
      }

      engine.Account_Create(account, function (pkgs) {
        if (pkgs.code == 0) {
          that.snackbar.text = "creation de compte réussi, un email de validation vient de vous être envoyé."
          that.snackbar.color = 'green'
          that.snackbar.isOpen = true
          that.show = false
          setInterval(function () {
            that.$emit('close')
          }, 3000)
        } else {
          if (pkgs.code == -100) {
            that.snackbar.text = "Cette adresse email est déjà utilisée."
            that.snackbar.color = 'red'
            that.snackbar.isOpen = true
          } else {
            that.snackbar.text = "Une erreur est survenue lors de la création du compte"
            that.snackbar.color = 'red'
            that.snackbar.isOpen = true
          }
        }
      });
    }
  }
}
</script>

<style></style>