<template>
  <v-dialog v-model="showStripe">
    <StripeCheckout urlRedirect="edit_facture" :validationFunction="validationFunction" :stripePrice="stripePrice"
      @close="showStripe = false" :clientSecret="clientSecret">
    </StripeCheckout>
  </v-dialog>
  <v-dialog v-model="payZenModalIsopen" persistent>
    <PayzenPayment @close="closePayZenModal()" :validationFunction="validationFunction" v-if="generatedPayZenKey"
      :generatedPayZenKey="generatedPayZenKey" :redirect="false">
    </PayzenPayment>
  </v-dialog>
  <v-dialog v-if="fileIsSelected" v-model="fileIsSelected" max-width="1300" width="99%">
    <pdf-viewer @closeModal="closeModal" :fileIsSelectedData="fileIsSelectedData"></pdf-viewer>
  </v-dialog>
  <v-col class="px-1 py-0">
    <v-card class="pa-2" v-if="isLoading == false" variant="flat" color="transparent">
      <v-col align="left" class="pl-0 pt-0">
        <h3 style="color: var(--C1);font-weight: bold;" class="titleText">
          Liste des documents disponible </h3>
      </v-col>
      <v-select variant="outlined" label="Séléctionner une réservation" :items="resaListe" v-model="selectedResa"
        clearable>
      </v-select>
      <Vue3EasyDataTable table-class-name="customize-table" :headers="headers" :items="getFilesListe"
        :table-height="windowHeight * 0.7" rows-per-page-message="Fichier par page" rows-of-page-separator-message="total"
        header-text-direction="center" body-text-direction="center" empty-message="Aucun document disponible">
        <template #item-date="item">
          <v-col align="center" style="cursor: pointer;">
            <div align="center" style="font-weight: bold">
              {{ item.date.replaceAll('-', '/') }}
            </div>
          </v-col>
        </template>
        <template #item-title="item">
          <v-col align="center" style="cursor: pointer;">
            <div style="font-weight: bold;" class="">
              {{ item.title }}
            </div>
          </v-col>
        </template>
        <template #item-type="item">
          <v-col align="center" style="cursor: pointer;">
            <div style="position: relative;font-weight: bold;">
              {{ item.type }}
            </div>
          </v-col>
        </template>
        <template #item-show="item">
          <v-btn icon="mdi-folder-open-outline" variant="text" density="small" @click="showFile(item)">
          </v-btn>
        </template>
        <template #item-paye="item">
          <v-btn v-if="factureToPayListe[item.num]" style="background-color: var(--C1);color: white;"
            icon="mdi-credit-card-outline" variant="text" size="35"
            @click="set_paiement_token(factureToPayListe[item.num])">
          </v-btn>
        </template>
      </Vue3EasyDataTable>
    </v-card>
    <basic-loading v-else style="margin-top:30vh"></basic-loading>
  </v-col>
  <v-snackbar :style="{ 'color': snackBar.color }" style="position: absolute; bottom: 300px;color: white;"
    v-model="snackBar.show" timeout="5000" :color="snackBar.color">
    {{ snackBar.value }}
  </v-snackbar>
</template>
  
<script>

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import BasicLoading from '@/components/basic-loading.vue'
import PayzenPayment from "@/components/payment/payzen_payment.vue"
import StripeCheckout from '@/components/payment/stripe_checkout.vue'


import engine from '@/core/DataAPI'
import PdfViewer from './pdf-viewer.vue'

export default {
  props: {
    userInformation: {},
    factureToPayListe: {},
    updateFacture: { type: Function }
  },
  data() {
    return {
      stripePrice: undefined,
      showStripe: undefined,
      clientSecret: undefined,
      selectedFacture: undefined,
      snackBar: {},
      filesListe: [],
      payZenModalIsopen: false,
      generatedPayZenKey: undefined,
      isLoading: true,
      fileIsSelected: false,
      fileIsSelectedData: undefined,
      selectedResa: undefined,
      resaListe: [],
      windowHeight: window.innerHeight,
      selectedFiles: [],
      headers: [
        { text: "Type", value: "type", sortable: true },
        { text: "Numéro", value: "num", sortable: true },
        { text: "Réservation", value: "resa", sortable: true },
        { text: "Nom", value: "title", sortable: true },
        { text: "Date", value: "date", sortable: true },
        { text: "Ouvrir", value: "show", sortable: true },
        { text: "A régler", value: "paye", sortable: true },
        // { text: "Télécharger", value: "selected", sortable: true },
      ],
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    this.updateFileListe()
    this.updateData()
  },
  watch: {
    selectedResa(val) {
      this.updateData(val)
    }
  },
  methods: {
    updateData() {
      this.updateFacture()
      this.filesListe = []
      let that = this
      let path = "CLIENT/" + this.userInformation.code
      for (let a = 0; a < this.resaListe.length; a++) {
        engine.get_files_liste_from_path(path + '/' + this.resaListe[a] + '/CONTRAT', function (data) {
          if (data.code == 0) {
            for (let i = 0; i < data.data.length; i++) {
              let fileData = data.data[i]
              let fileName = fileData.name.split('@@')
              that.filesListe.push({
                resa: that.resaListe[a],
                fileData: fileData,
                title: fileName[1],
                num: fileName[0],
                date: fileName[2],
                type: 'CONTRAT'
              })
            }
          }
        })
      }
      for (let a = 0; a < this.resaListe.length; a++) {
        engine.get_files_liste_from_path(path + '/' + this.resaListe[a] + '/FACTURE', function (data) {
          if (data.code == 0) {
            for (let i = 0; i < data.data.length; i++) {
              let fileData = data.data[i]
              let fileName = fileData.name.split('@@')
              that.filesListe.push({
                resa: that.resaListe[a],
                fileData: fileData,
                title: fileName[1],
                num: fileName[0],
                date: fileName[2],
                type: 'FACTURE'
              })
            }
          }
        })
      }
    },
    async set_paiement_token(facture) {
      let that = this
      this.selectedFacture = facture
      console.log("facture", facture);

      engine.createPaymentIntent(parseFloat((facture.MONTANT * 100).toFixed(2)), facture.NUMERO, function (data) {
        console.log("createPaymentIntent", data);
        that.clientSecret = data.data.clientSecret
        that.showStripe = true
        that.stripePrice = parseFloat((facture.MONTANT * 100).toFixed(2))
        console.log('stripePrice', that.stripePrice);
      })
    },
    validationFunction(callback) {
      let that = this
      // if (data.orderStatus == 'PAID') {
      // this.snackBar = { show: true, color: 'green', value: 'Payment valide' }
      // engine.edit_facture(this.selectedFacture.CODE_CLIENT, this.selectedFacture.NUMERO, function () {
      callback()
      that.updateData(that.selectedResa)
      // })
      // } else {
      //   this.snackBar = { show: true, color: 'red', value: 'ERREUR' }
      //   that.updateData(that.selectedResa)
      // }
      // this.closeStripeModal()
    },
    closePayZenModal() {
      this.payZenModalIsopen = false
      this.generatedPayZenKey = undefined
    },
    closeStripeModal() {
      this.showStripe = false
      this.clientSecret = undefined
    },
    showFile(item) {
      this.fileIsSelected = true
      this.fileIsSelectedData = item
    },
    downloadFile(fileData) {
      engine.file_download(fileData, function (data) {
        if (data.code == 0) {
          window.location.href = data.data.result.link;
        }
      })
    },
    closeModal() {
      this.fileIsSelected = false
      this.fileIsSelectedData = undefined
    },
    onResize() {
      this.windowHeight = window.innerHeight
    },
    getColorByType() {
      return "#7986CB"
    },
    updateFileListe() {
      let that = this
      let path = "CLIENT/" + this.userInformation.code
      engine.get_files_liste_from_path(path, function (data) {
        if (data.code == 0) {
          let files = data.data.map((val) => val.name)
          that.resaListe = files
          that.selectedResa = that.resaListe[0]
          that.isLoading = false
        } else {
          that.isLoading = false
        }
      })
    }
  },
  computed: {
    getFilesListe() {
      console.log("getFilesListe", this.selectedResa);
      if (this.selectedResa && this.selectedResa != null) {
        console.log(0);
        return this.filesListe.filter((val) => val.resa == this.selectedResa)
      } else {
        console.log(1);
        return this.filesListe
      }
    },
  },
  components: {
    Vue3EasyDataTable,
    BasicLoading,
    PdfViewer,
    PayzenPayment,
    StripeCheckout
  }
}
</script>
  
<style>
.customize-table {
  /* --easy-table-border: 1px solid #445269;
  --easy-table-row-border: 1px solid #445269; */

  /* --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-font-color: #c1cad4;
  --easy-table-header-background-color: #2d3a4f; */

  /* --easy-table-header-item-padding: 10px 15px; */

  /* --easy-table-body-even-row-font-color: #fff;
  --easy-table-body-even-row-background-color: #4c5d7a; */

  --easy-table-body-row-font-color: black;
  /* --easy-table-body-row-background-color: lightgrey; */
  /* --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 14px; */

  --easy-table-body-row-hover-font-color: white;
  --easy-table-body-row-hover-background-color: var(--C1);

  /* --easy-table-body-item-padding: 10px 15px; */

  /* --easy-table-footer-background-color: #2d3a4f;
  --easy-table-footer-font-color: #c0c7d2;
  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px; */

  /* --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1; */


  /* --easy-table-scrollbar-track-color: #2d3a4f;
  --easy-table-scrollbar-color: #2d3a4f;
  --easy-table-scrollbar-thumb-color: #4c5d7a;
  ;
  --easy-table-scrollbar-corner-color: #2d3a4f;

  --easy-table-loading-mask-background-color: #2d3a4f; */
}
</style>