<template>
  <div class="container">
    <div id="myPaymentForm"
      style="min-width: 350px; background-color: white;border: solid 1px lightgray;position: relative;" class="pa-2"
      align=center>
      <v-icon style="position: absolute; right: 2px;top:2px;cursor: pointer;" @click="close()"
        color="red">mdi-close-thick</v-icon>
      <h3>Finaliser mon paiement</h3>
      <v-divider class="my-2"></v-divider>
      <div>
        <div class="kr-embedded pa-2" kr-card-form-expanded></div>
      </div>
    </div>
  </div>
</template>

<script>

import KRGlue from '@lyracom/embedded-form-glue'

export default {
  data() {
  },
  props: {
    redirect: {},
    validationFunction: { type: Function },
    generatedPayZenKey: {}
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async setPayzen() {
      const endpoint = 'https://demo-location.accen.me/#/reservation'
      // const publicKey = '51173854:testpublickey_KM6cFTdiUFnzTAV9kmOjJFZO4H6dUAcuupYloneHaC2U2'
      const publicKey = '51173854:publickey_y5Cq8gHsXzM5Fc8usfN8lhwX2PMUshO9pvTS67L1kI37M'
      const formToken = this.generatedPayZenKey

      const { KR } = await KRGlue.loadLibrary(endpoint, publicKey)
      await KR.setFormConfig({       /* set the minimal configuration */
        formToken: formToken,
        'kr-language': 'fr-FR',                       /* to update initialization parameter */
      })
      const { result } = await KR.attachForm('#myPaymentForm') /* create a payment form */
      await KR.showForm(result.formId);  /* show the payment form */

      await KR.onSubmit((paymentResponse) => {
        let rep = JSON.parse(paymentResponse['rawClientAnswer'])
        if (this.redirect != false) {
          if (rep.orderStatus == 'PAID') {
            if (this.validationFunction != undefined) {
              this.validationFunction()
              this.$router.push({ path: '/validation', query: { type: 'renouvellement' } })
            } else {
              this.$router.push({ path: '/validation', query: { type: 'succes' } })
            }
          } else {
            this.$router.push({ path: '/validation', query: { type: 'error' } })
          }
        }else{
          this.validationFunction(rep)
        }
      })
    }
  },
  components: {
  },
  mounted() {
    this.setPayzen()
  }
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>