// import { createRouter, createWebHistory } from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router'

import ReservationView from '../views/ReservationView.vue'
import HomeView from '../views/HomeView.vue'
import ClientView from '../views/ClientView.vue'
import PlanningView from '../views/PlanningView.vue'
import ValidationView from '../views/ValidationView.vue'
import DocumentsView from '../views/DocumentsView.vue'

import AccountLogin from '../views/AccountLoginView.vue'
import AccountCreate from '../views/AccountCreateView.vue'

import StripeView from '../views/StripeView.vue'

const routes = [{
    path: '/',
    name: 'home',
    component: HomeView
}, {
    path: '/reservation',
    name: 'reservation',
    component: ReservationView
}, {
    path: '/validation',
    name: 'validation',
    component: ValidationView
}, {
    path: '/client',
    name: 'client',
    component: ClientView
}, {
    path: '/planning',
    name: 'planning',
    component: PlanningView
}, {
    path: '/documents',
    name: 'documents',
    component: DocumentsView
}, {
    path: '/account/login',
    name: 'AccountLogin',
    component: AccountLogin
}, {
    path: '/account/create',
    name: 'AccountCreate',
    component: AccountCreate
}, {
    path: '/payment/resume',
    name: 'Payment',
    component: StripeView
}]

const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    history: createWebHashHistory(),
    routes
})

export default router