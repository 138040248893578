import { ref } from "@vue/reactivity";
import engine from "@/core/DataAPI"

import { watch } from "vue";
// import { computed } from "@vue/runtime-core";
import { onMounted } from "vue";
import { useRouter } from 'vue-router'

// import moment from "moment";

export default function clientHook() {

    const userInformation = ref(undefined)
    const isValidUser = ref(false)
    const verifClientIsOver = ref(false)
    const actifContrat = ref(undefined)
    const router = useRouter()

    const isEmailValid = function(email) {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ) != null;
    };

    const login = function(mail, pwd, callback) {
        if (isEmailValid(mail) == false) {
            callback('Email introuvable')
        } else {
            engine.Account_Login(mail, pwd, function(data) {
                if (data.code == 0) {
                    engine.update_user_token(data.data[0].code, function(tokenData) {
                        if (tokenData.code == 0) {
                            localStorage.setItem('token', tokenData.data.id)
                            userInformation.value = data.data[0]
                            isValidUser.value = true
                            callback(true)
                        }
                    })
                } else {
                    callback('Compte introuvable')
                    isValidUser.value = false
                }
            })
        }
    }

    const getActifContrat = function() {
        console.log("getActifContrat");
        engine.get_actif_contract_by_client(userInformation.value.code, function(data) {
            console.log("get_actif_contract_by_client", data);
            if (data.code == 0) {
                actifContrat.value = data.data.data.map((val) => val.fieldData)
                console.log("actifContrat", actifContrat.value);
            }
        })
    }

    const updateUserInfo = function() {
        engine.get_user_info(function(data) {
            if (data.code == 0) {
                isValidUser.value = true
                userInformation.value = data.data[0]
            } else {
                isValidUser.value = false
                userInformation.value = undefined
            }
        })
    }

    const contratRenew = function(elem, email, nom, callback) {
        engine.renew_contrat(elem, email, nom, function(data) {
            if (data.code == 0) {
                router.push({ path: '/validation', query: { type: 'renouvellement' } })
            } else {
                router.push({ path: '/validation', query: { type: 'error' } })
            }
            callback()
        })
    }


    const isUserConnected = function() {
        let token = localStorage.getItem('token')
        if (token != undefined && token != "") {
            engine.get_user_info(function(data) {
                console.log(0, data);
                if (data.code == 0) {
                    isValidUser.value = true
                    userInformation.value = data.data[0]
                    getActifContrat()
                } else {
                    isValidUser.value = false
                    userInformation.value = undefined
                }
                verifClientIsOver.value = true
            })
        } else {
            verifClientIsOver.value = true
            userInformation.value = undefined
            isValidUser.value = false
        }
    }

    onMounted(() => {
        isUserConnected()
    })

    watch(() => isValidUser.value, (val, oldVal) => {
        if (val != oldVal) {
            isUserConnected()
        }
    });

    return {
        login,
        userInformation,
        isValidUser,
        verifClientIsOver,
        isUserConnected,
        updateUserInfo,
        actifContrat,
        contratRenew
    };

}