<template>
  <div class="pa-2 reservation" style="">
    <!-- <v-col align="center">
      <p style="
          color: var(--C2);
          font-size: 18px;
          padding: 0px;
          margin: 0px;
          font-weight: bold;
        ">
        Sélectionner une date de retrait
      </p>
    </v-col> -->
    <v-col class="pt-0 px-0" style="border: solid 1px lightgrey; border-radius: 5px">
      <div>
        <v-dialog v-model="isModalDataOpen" width="90%" max-width="600">
          <v-card>
            <v-col>
              <v-row class="py-1" justify="center" align="center">
                <v-col cols="12" class="py-1" align="center">
                  <v-card variant="text" class="pa-2"
                    style="border: solid 1px lightgrey;">
                    <div style="font-size: 18px; font-weight: bold">
                      Adresse
                    </div>
                    <!-- {{ agency }} -->
                    {{ agency.PR_NOM_AG }}
                    {{ agency.ADRESSE }}
                  </v-card>
                </v-col>
                <!-- <v-col cols="12" class="py-1" align="center">
                  <v-card outlined class="pa-2">
                    <div style="font-size: 18px; font-weight: bold">
                      Contact
                    </div>
                    {{ agency }}
                    {{ agency.phone }}
                    {{ agency.mail }}
                  </v-card>
                </v-col> -->
                <v-col v-for="(elem, idx) in modalForm" :key="idx" cols="12" class="py-1">
                  <v-card class="pa-2" align="center" variant="flat" outlined style="
                                    border: solid 1px lightgrey;
                                    text-transform: capitalize;
                                  ">
                    <div v-if="
                      elem.key1 == 'dates' &&
                      modalData[elem.key1] &&
                      modalData[elem.key1][elem.key2]
                    ">
                      {{ elem.label }} -
                      {{
                        formatDate(
                          modalData[elem.key1][elem.key2],
                          "DD MMMM YYYY HH:mm"
                        )
                      }}
                    </div>
                    <div v-if="
                      elem.key1 != 'dates' &&
                      modalData[elem.key1] &&
                      modalData[elem.key1][elem.key2]
                    ">
                      {{ elem.label }} -
                      {{ modalData[elem.key1][elem.key2] }}
                    </div>
                  </v-card>
                </v-col>
                <v-col align="center">
                  <v-btn class="mx-1" variant="tonal" style="color:white;background-color: var(--C1);"
                    @click="valideEvent()">
                    Valider ma séléction
                  </v-btn>
                  <v-btn variant="tonal" color="red" style="color:white" @click="isModalDataOpen = false">
                    Fermer
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-dialog>
        <v-dialog v-if="false" v-model="isAgencySelectorOpen" style="width: 90%; max-width: 500px">
          <v-card>
            <v-item-group :multiple="isAdmin" v-model="selectedAgency">
              <v-col>
                <v-row class="px-0 pt-0 mb-1">
                  <v-col align="center" class="px-0 pt-0">
                    <v-card class="pa-2" color="#5C6BC0" variant="flat" style="border-radius: 0px; color: white">
                      <h2>LISTE DES AGENCES</h2>
                    </v-card>
                  </v-col>
                </v-row>
                <div style="border: solid 1px lightgrey; border-radius: 5px" class="pa-2">
                  <div style="font-weight: bold">
                    Sélectioné les agences que vous souhaitez afficher
                  </div>
                  <v-divider class="mt-1"></v-divider>
                  <v-row class="px-0 pt-1 ma-2" style="max-height: 400px; overflow: auto">
                    <v-col v-for="agency in agencyList" :key="agency.id" cols="12" class="px-0">
                      <v-item v-slot="{ isSelected, toggle }" :value="agency">
                        <v-card align="center" :color="!isSelected ? '#E0E0E0' : ''" class="pa-1" variant="outlined"
                          @click="toggle">
                          {{ agency.title }}
                        </v-card>
                      </v-item>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-item-group>
            <!-- {{ agencyList }} -->
          </v-card>
        </v-dialog>
      </div>
      <div class="mr-0" style="position:relative" align="center">
        <div v-if="calendarErrorData.show" style="position:absolute;width:100%;top:200px;z-index: 3;" align="center">
          <v-card  style="z-index:2;background-color: var(--C1);border:solid 1px lightgrey;color:white" variant="flat"  width="80%">
            <div v-if="calendarErrorData.msg" style="width:80%" class="pa-2">
              {{ calendarErrorData.msg }}
              <a v-if="calendarErrorData.link && calendarErrorData.link.url" :href="calendarErrorData.link.url" style="color:black"> {{ calendarErrorData.link.label }} </a>
            </div>
            <v-btn v-if="calendarErrorData.showbtn == true"   @click="goToDate(calendarErrorData.newDate)"
              style="width:100%;color:white;background-color: var(--C1);" variant="flat">
              {{ calendarErrorData.btnMsg }}
            </v-btn>
          </v-card>
        </div>
        <full-calendar id="calendar" ref="fullCalendar" style="margin-top: 10px;" class="mx-2" :options="calendarOptions">
        </full-calendar>
      </div>
    </v-col>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue3";
// import "@fullcalendar/core/vdom";
import interactionPlugin from "@fullcalendar/interaction";
// import rrulePlugin from "@fullcalendar/rrule";
import timeGridPlugin from "@fullcalendar/timegrid";
import fr from "@fullcalendar/core/locales/fr";
import moment from "moment";
import 'moment/locale/fr'
// import calendarData from "@/hooks/calendar";
import engine from "@/core/DataAPI";

export default {
  data() {
    return {
      calendarErrorData: {},
      dispoList: [],
      isAgencySelectorOpen: false,
      modalForm: [
        // { key1: "agence", key2: 0, label: "Agence" },
        { key1: "dates", key2: "start", label: "RDV" },
        // { key1: "dates", key2: "end", label: "Fin" },
      ],
      isModalDataOpen: false,
      modalData: [],
      eventList: [],
      isAdmin: true,
      selectedEvent: undefined,
      selectedAgency: [],
      eventsColor: {
        free: { color: "rgb(189, 189, 189)", label: "Disponible" },
        notFree: { color: "#5C6BC0", label: "Pas Disponible" },
      },
      agencyList: [],
      currentCalendarDate: { start: undefined, end: undefined }
    };
  },
  props: {
    list_agencies: {},
    agency: {},
  },
  components: {
    FullCalendar,
  },
  watch: {
    dispoList(val) {
      let that = this
      if (val.length == 0) {
        // this.calendarErrorData = {
        //   show: true,
        //   msg: 'test',
        //   showbtn: true,
        //   btnMsg: 'testBtn',
        //   newDate: undefined
        // }
        let limite = moment().add(2, 'd').set({ hour: 0, minute: 0, second: 0 }).format('x')
        let start = this.currentCalendarDate.start
        let end = this.currentCalendarDate.end
        if (parseInt(start) < parseInt(limite)) {
          start = limite
        }
        end = moment(parseInt(end)).add(30, 'd').set({ hour: 23, minute: 59, second: 59 }).format('x')
        // let end = moment(this.currentCalendarDate.end).format('x')
        let engineData = [{
          'AGENCE': '==' + this.agency.PR_NOM_AG,
          'VILLE': '==' + this.agency.PR_VILLE_AG,
          // 'AGENCE': '==' + this.agency.PR_NOM_AG,
          // 'VILLE': '==' + this.agency.PR_VILLE_AG,
          'TYPE_ID': '==1',
          'JOUR': start + '...' + end,
          'SUPPRIMER': '==0',
          'LIBRE': '==0',
        },
        ]
        engine.get_dispo_between_date_by_types_and_agency(
          engineData, function (data) {
            if (data.code == 0) {
              let tab = data.data.data.sort((a, b) => a.fieldData.JOUR - b.fieldData.JOUR);
              that.calendarErrorData = {
                show: true,
                showbtn: true,
                btnMsg: 'Prochaine disponibilité le : ' + moment(tab[0].fieldData.JOUR).format('DD/MM/YYYY'),
                newDate: moment(tab[0].fieldData.JOUR)
              }
            } else {
              that.calendarErrorData = {
                show: true,
                msg: 'Aucun RDV disponible sur cette commune pour les 30 prochains jours. N\'hésitez pas à choisir un autre lieu de distribution ci-dessus ou à contacter.',
                // link: { label: 'Avaé', url: 'https://www.leveloplus.com/contact' },
                showbtn: false,
                // btnMsg: 'testBtn',
                // newDate: undefined
              }
            }
          })
      }
    },
    agency() {
      this.selectedEvent = undefined
      this.updateDispoGestion();
    },
  },
  mounted() {
    moment.locale('fr')
    // this.updateDispoGestion();
  },
  computed: {
    showPrev() {
      if (this.currentCalendarDate.start <= moment().format('x'))
        return false
      return true
    },
    isMobile() {
      return this.$vuetify.display.mobile;
    },
    calendarOptions() {
      let option = {};
      option = {
        timeZone: "UTC",
        selectable: true,
        // schedulerLicenseKey: "0359429366-fcs-1650894843",
        // allDaySlot: false,
        // forceEventDuration: true,
        plugins: [timeGridPlugin, interactionPlugin],
        // plugins: [timeGridPlugin, interactionPlugin, rrulePlugin],
        initialView: this.isMobile ? "timeGridWeek3Days" : "timeGridWeek",
        height: "350px",
        titleFormat: { year: "numeric", month: "short", day: "numeric" },
        locale: fr,
        eventClick: this.handleEventClick,
        slotEventOverlap: false,
        allDayText: '',
        // select: this.handleDateClick,
        // eventDrop: this.handleEventEdition,
        // eventResize: this.handleEventEdition,
        datesSet: this.handleDateChange,
        events: this.eventList,
        // slotMinTime: "08:00:00",
        // slotMaxTime: "18:00:00",
        // slotDuration: "00:30",
        // hiddenDays: [0],
        // slotWidth: 50,
        // slotLabelInterval: '01:00:00',
        views: {
          timeGridWeek3Days: {
            type: 'timeGrid',
            duration: { days: 3 },
            buttonText: '3 Jours'
          },
          // resourceTimeGridMoreDaysNoAdmin: {
          //     type: 'resourceTimeGrid',
          //     duration: { days: 7 },
          //     buttonText: '7 Jours'
          // },
        },
        // resources: isAdmin.value ? selectedAgency.value : [selectedAgency.value],
        headerToolbar: {
          left: this.showPrev ? "prev" : '',
          center: "title",
          right: "next",
        },
      };
      return option;
    },
  },
  methods: {
    goToDate(date) {
      date = moment(date).format('YYYY-MM-DD')
      if (date != undefined && this.$refs.fullCalendar) {
        let calendarApi = this.$refs.fullCalendar.getApi()
        calendarApi.gotoDate(date);
      }
    },
    handleDateChange(date) {
      let startTimestanp = moment(date.startStr).set({ hour: 0, minute: 0, second: 0 }).format('x')
      let endTimestanp = moment(date.endStr).subtract(1, 'd').set({ hour: 23, minute: 59, second: 59 }).format('x')
      if (this.currentCalendarDate.start != startTimestanp && this.currentCalendarDate.end != endTimestanp) {
        this.currentCalendarDate.start = startTimestanp
        this.currentCalendarDate.end = endTimestanp
        this.updateDispoGestion(startTimestanp, endTimestanp)
      }
    },
    updateDispoGestion(startTimestanp, endTimestanp) {
      let that = this
      if (startTimestanp == undefined)
        startTimestanp = this.currentCalendarDate.start
      if (endTimestanp == undefined)
        endTimestanp = this.currentCalendarDate.end

      let limite = moment().add(2, 'd').set({ hour: 0, minute: 0, second: 0 }).format('x')
      let fmDateStart = undefined
      if (parseInt(startTimestanp) < parseInt(limite)) {
        fmDateStart = limite
      } else {
        fmDateStart = startTimestanp
      }
      let engineData = [{
        'AGENCE': '==' + this.agency.PR_NOM_AG,
        'VILLE': '==' + this.agency.PR_VILLE_AG,
        'TYPE_ID': '==0',
        'JOUR': fmDateStart + '...' + endTimestanp,
        'SUPPRIMER': '==0',
        'LIBRE': '==0',

      }, {
        'AGENCE': '==' + this.agency.PR_NOM_AG,
        'VILLE': '==' + this.agency.PR_VILLE_AG,
        'TYPE_ID': '==1',
        'JOUR': fmDateStart + '...' + endTimestanp,
        'SUPPRIMER': '==0',
        'LIBRE': '==0',
      }]
      engine.get_dispo_between_date_by_types_and_agency(
        engineData, function (data) {
          that.dispoList = []
          that.eventList = []
          if (data.code == 0) {
            that.calendarErrorData = {}
            let tab = data.data.data;
            for (let i = 0; i < tab.length; i++) {
              that.dispoList.push({
                date: tab[i].fieldData.JOUR,
                dayFormated: moment(tab[i].fieldData.JOUR).format("YYYY-MM-DD"),
                timeStart: tab[i].fieldData.DEBUT,
                timeEnd: tab[i].fieldData.FIN,
                "Clé primaire": tab[i].fieldData["Clé primaire"],
              });
            }
            that.setWeeklyEvents();
          }
        })
    },
    valideEvent() {
      this.isModalDataOpen = false;
      this.setEvent(this.modalData);
    },
    formatDate(date, format) {
      // return moment(date).lang("fr").format(format);
      return moment(date).format(format);
    },
    handleEventClick(event) {
      let info = event.event._def;
      let dates = info.extendedProps.dates
      this.modalData = {
        info,
        dates,
      };
      this.isModalDataOpen = true;
    },
    setWeeklyEvents(key) {
      let freeEvents = [];
      for (let a = 0; a < this.dispoList.length; a++) {
        let dispo = this.dispoList[a];
        let title = dispo.timeStart
        if (key != dispo["Clé primaire"])
          freeEvents.push({
            title: title,
            start: dispo.dayFormated + "T" + dispo.timeStart + "00",
            end: dispo.dayFormated + "T" + dispo.timeEnd + "00",
            dates: {
              start: dispo.dayFormated + "T" + dispo.timeStart + ":00",
              end: dispo.dayFormated + "T" + dispo.timeEnd + ":00"
            },
            dispo: dispo,
            textColor: "black",
            // className: "disabledEvent",
            "Clé primaire": dispo["Clé primaire"],
            allDay: true,
          });
      }
      this.eventList = [...this.eventList, ...freeEvents];
    },
    setEvent(eventData) {
      let dispo = eventData.info.extendedProps.dispo
      if (this.selectedEvent == undefined || this.selectedEvent['Clé primaire'] != dispo["Clé primaire"]) {
        let title = dispo.timeStart
        this.selectedEvent = {
          title: title,
          // start: eventData.dates.start.replaceAll("-", "").replaceAll(":", ""),
          // end: eventData.dates.end.replaceAll("-", "").replaceAll(":", ""),
          // dates: {
          //   start: eventData.dates.start.replaceAll("-", "").replaceAll(":", ""),
          //   end: eventData.dates.end.replaceAll("-", "").replaceAll(":", "")
          // },
          start: dispo.dayFormated + "T" + dispo.timeStart + "00",
          end: dispo.dayFormated + "T" + dispo.timeEnd + "00",
          dates: {
            start: dispo.dayFormated + "T" + dispo.timeStart + ":00",
            end: dispo.dayFormated + "T" + dispo.timeEnd + ":00",
          },
          dispo: dispo,
          "Clé primaire": dispo["Clé primaire"],
          // color: "rgb(241, 138, 90)",
          className: "choosedEvent",
          type: "notFree",
          allDay: true,
        };
        this.eventList = [this.selectedEvent];
        this.setWeeklyEvents(eventData.info.extendedProps["Clé primaire"]);
        this.$emit("setMeetId", {
          "Clé primaire": eventData.info.extendedProps["Clé primaire"],
          "data":eventData.info.extendedProps
        });
      }
    }
  },
};
</script>
<style>
.reservation .choosedEvent {
  border-radius: 5px;
  background-color: var(--C1) !important;
  color: white;
}

.reservation .fc-event {
  text-align: center;
  border-radius: 5px;
  background-color: #F5F5F5;
  border: solid 1px lightgray;
  margin: 3px 3px;
  cursor: pointer;
}

.reservation .fc-event :hover {
  border-radius: 5px;
  background-color: var(--C1) !important;
  color: white;
}

.reservation .fc-scrollgrid-section-liquid {
  display: none;
}

.reservation .fc-daygrid-day {
  background-color: white;
  height: 254px;
  overflow: auto;
}

.reservation .fc-timegrid-divider {
  display: none;
}
</style>