<template>
  <v-dialog v-model="showStripe">
    <StripeCheckout urlRedirect="renew_contrat" :validationFunction="validationFunction" :stripePrice="stripePrice"
      @close="showStripe = false" :clientSecret="clientSecret">
    </StripeCheckout>
  </v-dialog>
  <v-dialog v-model="isVerifModalOpen" width="auto">
    <v-card align="center" style="background-color: var(--C5);">
      <v-col style="font-weight: bold;">
        Valider mon action ?
      </v-col>
      <v-col>
        <v-btn class="mx-2" color="#66BB6A" style="color:white" @click="validVerifModal()">
          Oui
        </v-btn>
        <v-btn class="mx-2" color="#EF5350" style="color:white" @click="isVerifModalOpen = false">
          Non
        </v-btn>
      </v-col>
    </v-card>
  </v-dialog>
  <v-dialog v-model="payZenModalIsopen" persistent>
    <PayzenPayment @close="closePayZenModal()" :validationFunction="validationFunction" v-if="generatedPayZenKey"
      :generatedPayZenKey="generatedPayZenKey">
    </PayzenPayment>
  </v-dialog>
  <home-header :login="login" :verifClientIsOver="verifClientIsOver" :headerTitle="headerTitle"
    :headerSecondeTitle="headerSecondeTitle" :userInformation="userInformation" :isValidUser="isValidUser"
    :isUserConnected="isUserConnected">
  </home-header>
  <v-col align=center class="main-vue" style="padding-bottom: 57px;">
    <v-col align="center" id="presentation">
      <h1 style="color: var(--C1);font-size: 40px;" class="titleText">Service de location ACCEN</h1>
      <!-- <v-card-title class="ml-0 pl-0" style="color: var(--C1);font-weight: bold;">Qui somme nous ?</v-card-title> -->
      <v-row justify="center" align="center" class="mt-2">
        <v-col cols="12" md="10">
          <h4>
            Bienvenue sur le site de réservation en ligne des services de mobilités ACCEN
          </h4>
          <h2 class="pa-0 pb-4 subTitletext" align="left" style="color: var(--C3);font-weight: bold;">
            Qui sommes-nous ?
          </h2>
          <div style="font-size: 16px;" align="left">
            Il s’agit du service de location longue durée de vélos, trottinettes et voitures sans permis proposé par la
            Communauté d’Agglomération Saumur
            Val-de-Loire, et dont la gestion a été confiée à Saumur Agglobus. <br>

            Vous vous engagez pour une location d'une durée de 1 à 12 mois, renouvelables.
          </div>
          <p style="font-weight: bold;" align="left">
            Sur le site de réservation en ligne des services de mobilités ACCEN<br><br>
            Des vélos avec assistance électrique<br>
            Des vélos avec assistance électrique de type cargo biporteur, triporteur et rallongé<br>
            Des vélos avec assistance électrique adapté pour les personnes à mobilité réduite<br>
            Des vélos classiques pour les étudiants<br>
            Des vélos classiques pliants<br>
            Des trottinettes électriques<br>
            Des voitures sans permis<br><br>
          </p>
          <v-divider class="mb-1" style="color:grey"></v-divider>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="10" v-if="isValidUser && verifClientIsOver">
      <h2 class="pa-0 pb-2 subTitletext" align="left" style="color: var(--C3);font-weight: bold;">
        Espace utilisateur
      </h2>
      <v-row>
        <v-col v-for="elem in clientMenu" :key="elem.title">
          <v-card variant="flat" height="100%" style="background-color: var(--C1);color: white" @click="goTo(elem.path)"
            class="pa-4 userMenu">
            <h2 class="pa-0 pb-0 subTitletext" align="center" style="font-weight: bold;">
              {{ elem.title }}
            </h2>
            <div>
              <v-icon size="50">
                {{ elem.icon }}
              </v-icon>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="actifContrat && actifContrat.length > 0">
        <v-col>
          <v-divider class="mt-1 mb-4" style="color:grey"></v-divider>
          <h2 class="pa-0 pb-0 subTitletext" align="left" style="color: var(--C3);font-weight: bold;">
            Contrat en cours </h2>
        </v-col>
        <v-col cols="12" align="left" style="" class="" v-if="contratLoading == false">
          <Vue3EasyDataTable table-class-name="customize-table2" :headers="headers" header-text-direction="center"
            :items="formatActifContrat" table-height="300" rows-per-page-message="Contrat par page" fixed-header
            hide-footer rows-per-page="100" rows-of-page-separator-message="total" body-text-direction="left"
            empty-message="Aucun contrat disponible">
            <template #item-start="item">
              <v-col align="">
                <div style="font-weight: bold;color:white" class="">
                  {{ formatDate(item.start, 'D MMMM YYYY') }}
                </div>
              </v-col>
            </template>
            <template #item-end="item">
              <v-col align="">
                <div style="font-weight: bold;" class="">
                  {{ formatDate(item.end, 'D MMMM YYYY') }}
                </div>
              </v-col>
            </template>
            <template #item-nb_jour_location="item">
              <v-col align="center">
                <div align='center'
                  :style="{ 'background-color': daysBeforeEnd(item.end) > 5 ? 'green' : 'red', 'color': 'white' }"
                  style="border-radius:5px">
                  <div class="pa-1" style="width: 100%;font-weight: bold;" align="center">
                    {{ daysBeforeEnd(item.end) }} Jour(s)
                  </div>
                </div>
              </v-col>
            </template>
            <template #item-renew="item">
              <v-col align="center">
                <div align="center">
                  <v-btn
                    v-if="item['Contrat Loc::web_renouvellement_interdit'] != 'Oui' && item['nb_jour_location'] <= 100"
                    variant="flat" style="background-color: var(--C5);color:black;border: solid 1px black;" @click="verifClick(function () {
                      set_paiement_token(item)
                    })">
                    Renouveler
                  </v-btn>
                </div>
              </v-col>
            </template>
          </Vue3EasyDataTable>
        </v-col>
        <v-col v-else cols="12" align=center>
          <basic-loading class="mt-1"></basic-loading>
        </v-col>
      </v-row>
      <v-divider class="mt-9" style="color:grey"></v-divider>
    </v-col>
    <v-col cols="12" md="10">
      <div>
        <div v-if="familyIsLoading == false">
          <redirection-reservation :familyList="familyList"></redirection-reservation>
        </div>
        <basic-loading class="mt-4" v-else></basic-loading>
      </div>
    </v-col>
  </v-col>
  <footer-general v-if="familyIsLoading == false" style="position:static;bottom: 0px !important;"></footer-general>
</template>

<script>

import moment from 'moment'
import HomeHeader from '@/components/header/header-general.vue'
import RedirectionReservation from '@/components/home/redirection_reservation.vue'
import FooterGeneral from '@/components/footer/footer-general.vue';
import PayzenPayment from "@/components/payment/payzen_payment.vue"
import Vue3EasyDataTable from 'vue3-easy-data-table';

import BasicLoading from '@/components/basic-loading.vue'

import homeHook from '@/hooks/homeHooks'
import clientHook from "@/hooks/clientHook"
import engine from "@/core/DataAPI"

import StripeCheckout from '../components/payment/stripe_checkout.vue'

export default {
  data() {
    return {
      clientSecret: undefined,
      showStripe: false,
      isVerifModalOpen: false,
      functionToRun: undefined,
      headers: [
        { text: "Numéro de contrat", value: "Contrat Loc::N° Contrat Comp", sortable: true },
        { text: "Numéro de réservation", value: "Contrat Loc::N° CDE Client", sortable: true },
        { text: "Materiel", value: "Descrip Materiel", sortable: true },
        { text: "Numéro de materiel", value: "N° Mat interne", sortable: true },
        { text: "Début", value: "start", sortable: true },
        { text: "Fin", value: "end", sortable: true },
        { text: "Jour(s) restant de location", value: "nb_jour_location", sortable: true },
        { text: "Renouveler mon contrat", value: "renew", sortable: true },
      ],
      contratLoading: false,
      selectedContrat: undefined,
      payZenModalIsopen: false,
      generatedPayZenKey: undefined,
    }
  },
  setup() {

    const clientMenu = [
      { title: 'Mon compte', icon: 'mdi-account', path: 'client' },
      { title: 'Gestion de RDV', icon: 'mdi-calendar', path: 'planning' },
      { title: 'Mes documents', icon: 'mdi-file', path: 'documents' }
    ]

    const {
      headerTitle,
      headerSecondeTitle,
      scrollToId,
      familyIsLoading,
      familyList
    } = homeHook()

    const { login, userInformation, isValidUser, verifClientIsOver, isUserConnected, actifContrat, contratRenew } = clientHook()

    return {
      headerTitle,
      headerSecondeTitle,
      scrollToId,
      familyIsLoading,
      familyList,

      isValidUser,
      verifClientIsOver,
      userInformation,
      isUserConnected,
      login,
      clientMenu,
      actifContrat,
      contratRenew
    }
  },
  methods: {
    // payer() {
    //   let that = this
    //   engine.createPaymentIntent(200, function (data) {
    //     console.log("create-payment-intent", data);
    //     that.clientSecret = data.data.clientSecret
    //     that.showStripe = true
    //   })
    // },
    validVerifModal() {
      this.functionToRun()
      this.isVerifModalOpen = false
      this.functionToRun = undefined
    },
    validationFunction(callback) {
      // let that = this
      console.log("contratRenew")
      // this.contratRenew(this.selectedContrat, this.userInformation.email, this.userInformation.lastname, function () {
      callback()
      // that.contratLoading = false
      // })
    },
    closePayZenModal() {
      this.payZenModalIsopen = false
      this.generatedPayZenKey = undefined
    },
    verifClick(callback) {
      this.functionToRun = callback
      this.isVerifModalOpen = true
    },
    // async set_paiement_token(contrat) {
    //   this.selectedContrat = contrat
    //   let amount = contrat['Contrat Loc::Prix Location Total_TTC']
    //   let numero = contrat['Contrat Loc::N° CDE Client']
    //   let that = this

    //   if (parseFloat(amount) == 0) {
    //     this.contratLoading = true
    //     this.validationFunction()
    //   } else {
    //     engine.create_payment_intent(numero, this.userInformation.email, parseFloat((amount * 100).toFixed(2)), function (data) {
    //       if (data.code == 0) {
    //         that.generatedPayZenKey = data.data.answer.formToken
    //         that.payZenModalIsopen = true
    //       }
    //     })
    //   }
    // },
    async set_paiement_token(contrat) {
      let that = this
      this.selectedContrat = contrat
      console.log("contrat", contrat);

      engine.createPaymentIntent(parseFloat((contrat['Contrat Loc::Prix Location Total_TTC'] * 100).toFixed(2)), contrat['Contrat Loc::numero_reservation'], function (data) {
        console.log("createPaymentIntent", data);
        that.clientSecret = data.data.clientSecret
        that.showStripe = true
        that.stripePrice = parseFloat((contrat['Contrat Loc::Prix Location Total_TTC'] * 100).toFixed(2))
        console.log('stripePrice', that.stripePrice);
      })
    }
    ,
    daysBeforeEnd(date) {
      return moment(date).diff(moment(), 'days')
    },
    formatDate(date, format) {
      return moment(date).local('fr').format(format)
    },
    goTo(url) {
      this.$router.push({ path: url, replace: true })
    },
  },
  watch: {
    isValidUser(val) {
      if (val == true) {
        this.scrollToId('presentation')
      }
    }
  },
  computed: {
    formatActifContrat() {
      let newtab = []
      for (let i = 0; i < this.actifContrat.length; i++) {
        let tmp = this.actifContrat[i]
        tmp['start'] = tmp['Date Debut Loc']
        tmp['end'] = tmp['Date retour']
        newtab.push(tmp)
      }
      console.log("total", this.actifContrat);
      return newtab
    }
  },
  components: {
    Vue3EasyDataTable,
    HomeHeader,
    RedirectionReservation,
    BasicLoading,
    FooterGeneral,
    PayzenPayment,
    StripeCheckout
  }
}
</script>

<style>
.customize-table2 {
  --easy-table-header-font-size: 15px;
  --easy-table-header-item-padding: 5px 5px;
  --easy-table-body-row-font-color: black;
  --easy-table-body-row-background-color: var(--C1);
  --easy-table-body-row-font-size: 15px;
  --easy-table-body-row-hover-font-color: white;
  --easy-table-body-row-font-color: white !important;
  --easy-table-body-row-hover-background-color: var(--C3);
  --easy-table-body-item-padding: 0px 15px;
}
</style>