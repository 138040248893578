<template>
  <client-header :login="login" :verifClientIsOver="verifClientIsOver" :headerTitle="headerTitle"
    :headerSecondeTitle="headerSecondeTitle" :userInformation="userInformation" :isValidUser="isValidUser"
    :isUserConnected="isUserConnected">
  </client-header>
  <v-row class="main-vue" justify="center" align="center" style="margin-top:25vh">
    <v-col cols="10" md="6">
      <v-card color="#EEEEEE" variant="flat" class="pa-2" style="border:solid 1px lightgrey">
        <div v-if="type == 0">
          <v-icon class="mb-3" size="90">mdi-emoticon-happy-outline</v-icon>
          <h2>
            Votre réservation a bien été prise en compte, un récapitulatif vient de vous être envoyé.
          </h2>
          <a href="/">Accueil</a>
        </div>
        <div v-if="type == 1">
          <v-icon class="mb-3" size="90">mdi-emoticon-sad-outline</v-icon>
          <h2>
            Une erreur c'est produit lors de votre règlement nous vous invitons à recommencer.
          </h2><br>
          <a href="/">Accueil</a> /
          <a href="/#/reservation">Reservation</a>
        </div>
        <div v-if="type == 2">
          <v-icon class="mb-3" size="90">mdi-emoticon-happy-outline</v-icon>
          <h2>
            Votre renouvellement a bien été prise en compte, un récapitulatif vient de vous être envoyé.
          </h2>
          <a href="/">Accueil</a>
        </div>
        <div v-if="type == 3">
          <v-icon class="mb-3" size="90">mdi-emoticon-happy-outline</v-icon>
          <h2>
            Le règlement de votre facture a bien été pris en compte.
          </h2><br>
          <a href="/">Accueil</a> /
          <a href="/#/documents">Document</a>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
    

<script>


import ClientHeader from '@/components/header/header-general.vue'
import clientHook from "@/hooks/clientHook"
// import Stripe from 'stripe';

export default {
  setup() {
    const { login, userInformation, isValidUser, verifClientIsOver, isUserConnected } = clientHook()
    return { login, userInformation, isValidUser, verifClientIsOver, isUserConnected }
  },
  data() {
    return {
      type: undefined,
      headerTitle: "",
      headerSecondeTitle: ""
    }
  },
  mounted() {
    this.checkStatus()
  },
  methods: {
    showMessage(msg) {
      console.log(msg)
    },
    async checkStatus() {
      switch (this.$route.query.redirect_status) {
        case "succeeded":
          this.type = 0
          this.showMessage("Payment succeeded!");
          if (this.$route.query.msg == 'facture')
            this.type = 3
          break;
        case "processing":
          this.type = 0
          this.showMessage("Your payment is processing.");
          if (this.$route.query.msg == 'facture')
            this.type = 3
          break;
        case "requires_payment_method":
          this.type = 1
          this.showMessage("Your payment was not successful, please try again.");
          break;
        default:
          this.type = 1
          this.showMessage("Something went wrong.");
          break;
      }
      this.$router.replace({ 'query': null });
    }

  },
  watch: {
    isValidUser(val) {
      if (val == false) {
        this.$router.push('/')
      }
    }
  },
  components: {
    ClientHeader,
  }
}
</script>

<style></style>