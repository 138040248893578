<template>
    <v-dialog v-model="isCeationCompteOpen" persistent>
        <compte-creation v-if="isCeationCompteOpen" @close="isCeationCompteOpen = false"
            :closeModal="closeModal"></compte-creation>
    </v-dialog>
    <div v-if="verifClientIsOver">
        <div v-if="isValidUser == false">
            <v-row justify="center" align="center" class="pb-3">
                <!-- <v-col cols="6" class="">
                    <v-icon class="mb-2" size="50" color="#424242">mdi-account-question</v-icon>
                    <div style="font-weight:bold;font-size: 18px;">
                        Si vous êtes déja titulaire d'un compte avaé se connecter pour suivre votre location.
                    </div>
                </v-col>
                <v-divider class="mt-3 mb-5"></v-divider> -->
                <v-col v-if="isRecoveryAccount == false" cols="12" class="pa-0">
                    <v-card variant="text" style="background-color: rgb(250,250,250);">
                        <v-col cols="12" v-if="connected == undefined"
                            style="color: var(--C1);font-weight: bold;font-size: 20px;">
                            Connection
                        </v-col>
                        <v-col cols="12" class="pb-0">
                            <v-col>
                                <v-text-field hide-details label="Mail" v-model="mail">
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field hide-details label="Mot de passe" type="password" v-model="password">
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-row>
                                    <v-col class="pa-1">
                                        <v-btn @click="verifInformation" style="background-color: var(--C1);color:white">Se
                                            connecter</v-btn>
                                    </v-col>
                                    <v-col class="pa-1">
                                        <v-btn variant="text" style="color:var(--C2)" @click="isCeationCompteOpen = true">
                                            Créer un compte
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col class="pb-0" align="center" cols="">
                                <!-- <div @click="isRecoveryAccount = true"
                                    style="text-decoration: underline;cursor:pointer;color: var(--C2);">
                                    mot de passe oublié
                                </div> -->
                                <v-btn style="text-decoration: underline;cursor:pointer;color: var(--C2);"
                                    @click="isRecoveryAccount = true" variant="text">
                                    mot de passe oublié
                                </v-btn>
                            </v-col>
                        </v-col>
                        <v-col cols="">
                            <v-card v-if="error.show" class="pa-2" width="auto" color="red" style="color:white">
                                {{ error.message }}
                            </v-card>
                        </v-col>

                    </v-card>
                </v-col>
                <v-col v-else cols="12">
                    <v-card style="width:100vw;max-width: 500px;" variant="flat" class="pa-2">
                        <div align="left" class="mb-3">
                            <v-btn @click="isRecoveryAccount = false" icon="mdi-arrow-left"
                                style="background-color: var(--C1);color: white;" density="compact">
                            </v-btn>
                        </div>
                        <h1 style="color: var(--C1);">
                            Réinitialisation de mot de passe
                        </h1>
                        <v-divider class="mb-2"></v-divider>
                        <h3 class="mb-3">
                            Pour envoyer une demande de réinitialisation de votre mot de passe, saisissez l'adresse e-mail
                            que vous utilisez pour vous connecter à votre compte.
                        </h3>
                        <v-text-field hide-details label="Mail" v-model="mail">
                        </v-text-field>
                        <v-col align="center" class="pb-0">
                            <v-btn variant="flat" style="background-color: var(--C1);color:white" class="mt-2"
                                @click="account_recovery">
                                Envoyer
                            </v-btn>
                        </v-col>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <v-col v-else-if="userInformation && connected == undefined" class="pa-2">
            <v-card-title class="pa-0 pb-4" align="left" style="color: var(--C1);font-weight: bold;">
                Espace personel
            </v-card-title>
            <v-row justify="center" align=center>
                <v-col v-for="(elem, i) in pages" :key="i" cols="6" class="pa-2">
                    <v-card min-height="200px" :color="elem.color" class="pa-2" @click="goTo(elem.url)"
                        style="padding-top: 50px !important;">
                        <v-icon size="30" :icon="elem.icon"></v-icon>
                        <h2>
                            {{ elem.title }}
                        </h2>
                        <h4 style="color:#424242">
                            {{ elem.description }}
                        </h4>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </div>
    <v-col v-else>
        <v-progress-circular :size="150" :width="3" color="purple" indeterminate></v-progress-circular>
    </v-col>
    <v-snackbar v-model="snackBar.value" timeout="5000" :color="snackBar.color">
        {{ snackBar.text }}
    </v-snackbar>
</template>

<script>

import compteCreation from "@/components/home/compte_creation.vue"
import engine from "@/core/DataAPI"

export default {
    data() {
        return {
            snackBar: {},
            mail: undefined,
            password: undefined,
            isCeationCompteOpen: false,
            isRecoveryAccount: false,
            error: { show: false, message: undefined },
            pages: [
                { title: 'Réserver un vélo', icon: 'mdi-bike', description: 'Effectuer une réservation de vélos et réaliser le paiement en ligne', color: '#81C784', url: 'reservation' },
                { title: 'Gestion de compte', icon: 'mdi-account', description: 'Effectuer une réservation de vélos et réaliser le paiement en ligne', color: '#A1887F', url: 'client' },
                { title: 'Planning', icon: 'mdi-calendar', description: 'Gestion et prise de rendez-vous', color: '#5C6BC0', url: "planning" },
                { title: 'Documents', icon: 'mdi-file-account-outline', description: 'Historique de vos factures et contrats de locations', color: '#FFAB91', url: "documents" },
            ]
        }
    },
    props: {
        connected: { type: Function },
        login: { type: Function },
        isUserConnected: { type: Function },
        userInformation: {},
        isValidUser: {},
        verifClientIsOver: {}
    },
    components: {
        compteCreation,
    },
    methods: {
        account_recovery() {
            let that = this
            engine.Account_Recovery_Ask(this.mail, function (data) {
                if (data.code == 0) {
                    that.snackBar.text = "Un email de réinitialisation vient de vous être envoyer."
                    that.snackBar.value = true
                    that.snackBar.color = '#66BB6A'
                } else {
                    that.snackBar.text = "Une erreur c'est produit lors de l'envoie de l'Email."
                    that.snackBar.value = true
                    that.snackBar.color = '#EF5350'
                }
            })
            that.isRecoveryAccount = false
        },
        closeModal() {
            this.isCeationCompteOpen = false
        },
        goTo(url) {
            this.$router.push(url)
        },
        verifInformation() {
            let that = this
            this.login(this.mail, this.password, function (msg) {
                if (msg != true) {
                    that.error = {
                        show: true,
                        message: msg
                    }
                    setTimeout(function () {
                        that.error.show = false
                    }, 2000)
                }
            })
        }
    }

}

</script>