<template>
  <div style="background-color: var(--C1);position:static;bottom: 0px;width: 100vw;">
    <v-col>
      <v-row align="center" style="color:white">
        <v-col class="py-1">
          <div>
            Mail : support@accen.fr <br>
            Tél : 06 06 06 06 06
          </div>
        </v-col>
        <v-col class="py-1">
          <a style="color:white" target="_blank" href="/CGLU.pdf">Condition générales de confidentialité</a>
        </v-col>
        <v-col class="py-1">
          Made by Accen informatique
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
export default {}
</script>

<style></style>