// import Vue from 'vue';
import axios from 'axios';

var CryptoJS = require("crypto-js");

class DataAPI {
    constructor() {
        this.URL = "https://demo-location.accen.me/api";
        this.cryptageKey = "2687da56-4181-48a6-bec4-c526727423f8"
    }

    encrypt(datas) {
        return { data: CryptoJS.AES.encrypt(JSON.stringify(datas), this.cryptageKey).toString() }
    }

    decrypt(data) {
        var bytes = CryptoJS.AES.decrypt(data, this.cryptageKey);
        var originalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return originalData
    }

    Guid() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
    }

    IsProduction() {
        return true;
    }

    Account_Login(login, pwd, callback) {
        var datas = { login: login, pwd: pwd };
        axios.post(this.URL + '/v1/account/login', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    update_user_token(user_id, callback) {
        var datas = { user_id };
        axios.post(this.URL + '/v1/update_user_token', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    get_user_info(callback) {
        let token = localStorage.getItem('token')
        var datas = { token };
        axios.post(this.URL + '/v1/get_user_info', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Logout(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/account/logout', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Email_Exist(login, callback) {
        var datas = { login: login };
        axios.post(this.URL + '/v1/account/exist', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Info(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/account/info', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.data)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Edit(code, data, callback) {
        var datas = { code, data };
        axios.post(this.URL + '/v1/account/edit', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.data)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Edit_Bank(data, callback) {
        var datas = { data: data };
        axios.post(this.URL + '/v1/account/edit/bank', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.data)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Create(data, callback) {
        var datas = { data: data };
        axios.post(this.URL + '/v1/account/create', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.data)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Create_Valid(apikey, callback) {
        var datas = { apikey: apikey };
        axios.post(this.URL + '/v1/account/create/valid', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.data)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Recovery_Ask(email, callback) {
        var datas = { email: email };
        axios.post(this.URL + '/v1/account/recovery/ask', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Recovery_Valid(apikey, pwd, callback) {
        var datas = { apikey: apikey, pwd: pwd };
        axios.post(this.URL + '/v1/account/recovery/valid', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Inactive(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/account/inactive', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.data)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Account_Delete(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/account/delete', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.data)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Agency_List(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/agency/list', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.data)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Material_List(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/material/list', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => {
            callback(this.decrypt(res.data));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    Family_List(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/family/list', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => {
            callback(this.decrypt(res.data));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    Rentals_List(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/rentals/list', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.data)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Invoices_List(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/invoices/list', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.data)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    File_Exist(filename, code, callback) {
        var datas = { filename, code };
        axios.post(this.URL + '/v1/file/exist', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.data)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    File_Invoice_Download(number, callback) {
        var datas = {};
        axios.post(this.URL + '/v1/file/download/invoice/' + number, this.encrypt(datas), {
            responseType: "arraybuffer",
            credentials: false
        }).then(res => { callback(res); }, reserror => { callback(reserror); });
    }

    File_Upload(code, extraPath, name, file, callback) {
        var datas = { file, extraPath, code, name }
        axios.post(this.URL + '/v1/file/upload', datas, {
            responseType: 'json',
            credentials: false,
            'Content-Type': `multipart/form-data`,
        }).then(res => {
            callback(res.data);
        }, reserror => { callback(reserror); });
    }

    File_download(code, name, callback) {
        let datas = { name, code }
        axios.post(this.URL + '/v1/file/download', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(res.data);
        });
    }

    GetHmac(paquet, callback) {
        var datas = { paquet: paquet };
        axios.post(this.URL + '/v1/hmac', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.data)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Recaptcha_Send(recaptcha_user, callback) {
        var datas = { recaptcha_user: recaptcha_user };
        axios.post(this.URL + '/v1/recaptcha', this.encrypt(datas), {
            responseType: 'json',
            credentials: false
        }).then(res => { callback(this.decrypt(res.data)); }, reserror => { callback(this.decrypt(reserror)); });
    }

    Reservation_Create(vdata, callback) {
        var datas = { source: vdata };
        axios.post(this.URL + '/v1/reservation/create', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        }, reserror => { callback(this.decrypt(reserror)); });
    }


    reservation_free(num, callback) {
        var datas = { num };
        axios.post(this.URL + '/v1/reservation/free', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    Reservation_Cancel(numero, callback) {
        var datas = { numero: numero };
        axios.post(this.URL + '/v1/reservation/cancel', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    Reservation_Nostock(client, callback) {
        var datas = { client };
        axios.post(this.URL + '/v1/reservation/nostock', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    Iban_verification(iban, callback) {
        var datas = { iban }
        axios.post(this.URL + '/v1/iban/verification', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        }, reserror => { callback(this.decrypt(reserror)); });
    }

    edit_dispo(resa, callback) {
        var datas = { resa };
        axios.post(this.URL + '/v1/edit_dispo', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_dispo(city, agency, today, callback) {
        var datas = { city, agency, today };
        axios.post(this.URL + '/v1/get_dispo', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_dispo_between_timestamp(city, agency, start, end, callback) {
        var datas = { city, agency, start, end };
        axios.post(this.URL + '/v1/get_dispo_between_timestamp', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_all_materials_pics(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/get_all_materials_pics', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_dispo_between_date_by_type(city, agency, type, start, end, callback) {
        var datas = { city, agency, type, start, end };
        axios.post(this.URL + '/v1/get_dispo_between_date_by_type', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_dispo_between_date_by_types_and_agency(fmData, callback) {
        var datas = { fmData };
        axios.post(this.URL + '/v1/get_dispo_between_date_by_types_and_agency', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }
    get_dispo_by_code_client(codeClient, callback) {
        var datas = { codeClient };
        axios.post(this.URL + '/v1/get_dispo_by_code_client', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_agency_liste(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/get_agency_liste', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_rdv_type_liste(callback) {
        var datas = {};
        axios.post(this.URL + '/v1/get_rdv_type_liste', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_no_actif_contract_by_client(code_client, callback) {
        var datas = { code_client };
        axios.post(this.URL + '/v1/get_no_actif_contract_by_client', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_actif_contract_by_client(code_client, callback) {
        var datas = { code_client };
        axios.post(this.URL + '/v1/get_actif_contract_by_client', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    create_payment_intent(numero, mail, price, callback) {
        var datas = { numero, mail, price };
        axios.post(this.URL + '/v1/create_payment_intent', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_material_pic_from_name(path, callback) {
        var datas = { dropBoxPath: path };
        axios.post(this.URL + '/v1/get_material_pic_from_name', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_files_liste_from_path(path, callback) {
        let datas = { dropBoxPath: path }
        axios.post(this.URL + '/v1/get_files_liste_from_path', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_file_from_path(dropBoxPath, callback) {
        let datas = { dropBoxPath }
        axios.post(this.URL + '/v1/get_file_from_path', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    renew_contrat(contrat, email, nom, callback) {
        let datas = { contrat, email, nom }
        axios.post(this.URL + '/v1/renew_contrat', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    get_facture(code, callback) {
        let datas = { code }
        axios.post(this.URL + '/v1/get_facture', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    edit_facture(code_client, numero, callback) {
        let datas = { code_client, numero }
        axios.post(this.URL + '/v1/edit_facture', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }

    createPaymentIntent(amount, num, callback) {
        console.log("createPaymentIntent");
        let datas = { amount, num }
        axios.post(this.URL + '/v1/create-payment-intent', this.encrypt(datas), {
            responseType: 'json',
            credentials: false,
            headers: {}
        }).then(res => {
            callback(this.decrypt(res.data));
        });
    }
}

export default new DataAPI()